<template>
  <div>
    <el-row>
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <!-- 订单信息 -->
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">订单信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">订单号：</div>
            <div class="wid-form">{{ details.orderNumber }}</div>
            <div class="form-input-100 tl-r">订单状态：</div>
            <div class="wid-form">{{ getString(1, details.status) }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">所属门店：</div>
            <div class="wid-form">{{ getStoreString(details.storeId) }}</div>
            <div class="form-input-100 tl-r">订单重量：</div>
            <div class="wid-form" v-if="details.weight">
              {{ details.weight }}g
            </div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">下单时间：</div>
            <div class="wid-form">{{ details.createTime }}</div>
            <div class="form-input-100 tl-r">支付时间：</div>
            <div class="wid-form">{{ details.paymentTime }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr" v-if="details.deliveryType == 0">
            <div class="form-input-100 tl-r">配送时间：</div>
            <div>{{order_send_time}}</div>
            <el-button class="iconfont icon-qishou-black iconfontSelf"
             type="primary" 
             style="margin-left:150px" 
             @click="createDadaOrder"
             v-if="details.deliveryType == 0 && details.pickingStatus == 1 && (details.status == 1 || details.status == 8)">立即呼叫骑手</el-button>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">用户备注：</div>
            <div>{{ details.remark }}</div>
          </div>
        </div>

        <!-- 收货人信息 -->
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">收货信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">收货人姓名：</div>
            <div class="wid-form">{{ details.linkman }}</div>
            <div class="form-input-100 tl-r">手机号码：</div>
            <div class="wid-form">{{ details.telephone }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">收货地址：</div>
            <div>{{ details.address }}</div>
          </div>
        </div>


        <!-- 商品信息 -->
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">商品信息</div>
          <el-table :data="goodsList">
            <el-table-column label="ID" prop="goodsId"></el-table-column>
            <el-table-column
              label="商品条形码"
              prop="goodsBarcode"
            ></el-table-column>
            <el-table-column
              label="商品编码"
              prop="goodsCode"
            ></el-table-column>
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.goodsImgs)"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column label="活动类型" prop="goodsType">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsType | goodsTypeFilter }}</span>
              </template>
            </el-table-column>
           <el-table-column
              label="核销状态"
              prop="checktype"
              v-if="details.status == 3 && details.deliveryType == 1"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.checktype == 1">未核销</span>
                <span v-else>已核销</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="details.status == 3 && details.deliveryType == 1"
              label="核销人员"
              prop="writeoffName"
            ></el-table-column>
            <el-table-column
              v-if="details.status == 3 && details.deliveryType == 1"
              label="核销时间"
              prop="photoTime"
            ></el-table-column>
            

            <el-table-column label="单价(实际支付价格)" prop="actualPrice">
              <template slot-scope="scope">
                <span>￥{{ scope.row.actualPrice?scope.row.actualPrice:scope.row.shopOrderNums[0].price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="goodsNum">
              <template slot-scope="scope">{{
                scope.row.goodsNum || 0
              }}</template>
            </el-table-column>
            <el-table-column label="重量" prop="goodsWeight">
              <template slot-scope="scope">
                <span v-if="scope.row.goodsWeight"
                  >{{ scope.row.goodsWeight }}g</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 支付信息 -->
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">支付信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">配送费：</div>
            <div class="wid-form">￥{{ details.deliveryFee }}</div>
            <div class="form-input-100 tl-r">打包费：</div>
            <div class="wid-form">￥{{ details.packingFee }}</div>
            <div class="form-input-100 tl-r" style="width:120px">运费减免金额：</div>
            <div class="wid-form">￥{{ details.freight }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">满减金额：</div>
            <div class="wid-form">￥{{ details.reductionAmount }}</div>
            <div class="form-input-100 tl-r">惠购券：</div>
            <div class="wid-form">￥{{ details.hgoCouponReduce }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">应付金额：</div>
            <div class="wid-form">￥{{ details.due }}</div>
            <div class="form-input-100 tl-r">实付金额：</div>
            <div class="wid-form">￥{{ details.actualPay || 0 }}</div>
          </div>
        </div> 

        
        <!-- 配送信息 -->
        <div class="pad-container mt-24" v-if="order_steps.length>0">
          <div class="fs-20">配送信息</div>
          <div v-for="step in order_steps" :key="step.id" class="order_step">
            <div style="width:150px">{{step.order_status}}：</div>
            <div style="width:150px">{{step.callbackTime}}</div>
            <div class="order_step_item" v-if="step.dm_name">骑手姓名：{{step.dm_name}}</div>
            <div class="order_step_item" v-if="step.dm_name">联系骑手：{{step.dm_mobile}}</div>
          </div>
        </div>
        
        <!-- 客户信息 -->
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="fs-20">购买客户信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">客户昵称：</div>
            <div class="wid-form">{{ customerObj.cmname }}</div>
            <div class="form-input-100 tl-r">客户ID：</div>
            <div class="wid-form">{{ customerObj.id }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">手机号：</div>
            <div class="wid-form">{{ customerObj.mobile }}</div>
            <div class="form-input-100 tl-r">会员等级：</div>
            <div class="wid-form">
              {{
                customerObj.cdmtype == 18
                  ? "金卡会员"
                  : customerObj.cdmtype == 20
                  ? "钻石会员"
                  : customerObj.cdmtype == 21
                  ? "新会员金卡"
                  : customerObj.cdmtype == 23
                  ? "铂卡会员"
                  : ""
              }}
            </div>
          </div>
        </div>
        
        <!-- 旧版配送信息 -->
        <div class="pad-container mt-24" v-if="details.meituanDistributionInformation">
          <!-- 操作栏 -->
          <div class="fs-20">配送信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">配送方式：</div>
            <div class="wid-form">美团配送</div>
            <div class="form-input-100 tl-r">配送状态：</div>
            <div class="wid-form">
              {{
                details.meituanDistributionInformation.status | giveTypeFilter
              }}
            </div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">手机号码：</div>
            <div class="wid-form">
              {{ details.meituanDistributionInformation.courierPhone }}
            </div>
            <div class="form-input-100 tl-r">配送员姓名：</div>
            <div class="wid-form">
              {{ details.meituanDistributionInformation.courierName }}
            </div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">配送费：</div>
            <div class="wid-form">￥{{ details.deliveryFee }}</div>
             <div class="form-input-100 tl-r">取消原因：</div>
            <div >{{ details.meituanDistributionInformation.cancelReason }}</div>
          </div>
        </div>
        

        <!-- 防损信息 -->
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">退款信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">退款操作人：</div>
            <div class="wid-form">{{ details.refundOperator }}</div>

            <div class="form-input-100 tl-r">防损责任人：</div>
            <div class="wid-form">{{ details.liablePerson }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">退款时间：</div>
            <div class="wid-form">{{ details.refundTime }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">退款理由：</div>
            <div>{{ details.refundReason }}</div>
          </div>
        </div>

         <!-- 自提 -->
        <div v-if="details.deliveryType == 1">
          <div class="pad-container mt-24">
            <div class="fs-20">自提地址</div>
            <div class="flx-row c-6 mt-24 ali-c">
              {{ details.storeName }}
            </div>
          </div>
          <div class="pad-container mt-24">
            <div class="fs-20">
              核销信息
            </div>
            <div class="flx-row c-6 mt-24 ali-c">
              <span>自提码:</span> &nbsp; &nbsp;
              <span>{{details.qrCode }}</span> 
              <img class="qrCode" :src="'https://wechat.lirimall.com/file-service/qrCode?code=10,'+details.qrCode"   />
            </div>
          </div>
        </div>
        <!-- 其他信息 -->
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">其他信息</div>
          <div class="mt-24 c-6 flx-row" v-if="details.status == 7">
            <div class="form-input-100 tl-r">拒单理由：</div>
            <div class="wid-form">{{ details.rejectionReason }}</div>
          </div>
        </div>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button class="form-button" type="primary" @click="$router.go(-1)"
            >返回</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script> 

import {
  queryUserMember,
  orderOSM,
  listOfPage,
} from "@/api/transactions/order/orders_home_details";
import {createDadaOrder} from "@/api/transactions/order/orders_department";
import '@/assets/icons/iconfont/iconfont.js'
export default {
  data() {
    return {
      pubId: null,
      details: {},
      goodsList: [],
      clientInfo: {},
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "未支付",
        },
        {
          id: 1,
          title: "已支付",
        },
        {
          id: 2,
          title: "已发货",
        },
        {
          id: 3,
          title: "已完成",
        },
        {
          id: 4,
          title: "退款中",
        },
        {
          id: 5,
          title: "已退款",
        },
        {
          id: 6,
          title: "已失效",
        },
        {
          id: 7,
          title: "已拒单",
        },
        {
          id: 8,
          title: "待配送",
        },
        {
          id: 9,
          title: "配送中",
        },
          {
          id: 10,
          title: "取货中",
        },
         {
          id: 12,
          title: "待调度",
        },
        {
          id: -1,
          title: "退款失败",
        },
      ],
      order_type: [
        {
          id: 1,
          title: "正常交易",
        },
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 3,
          title: "砍价活动",
        },
      ],
      store_list: [],
      memberLevel: null,
      customerObj: {},
      delivery_status:[
        {
          id:1,
          title:'待接单'
        },
        {
          id:2,
          title:'待取货'
        },
        {
          id:3,
          title:'配送中'
        },
        {
          id:4,
          title:'已完成'
        },
        {
          id:5,
          title:'已取消'
        },
        {
          id:8,
          title:'指派单'
        },
        {
          id:9,
          title:'配送异常物品返回'
        },
        {
          id:10,
          title:'配送异常返回成功'
        },
        {
          id:100,
          title:'骑士到店'
        },
        {
          id:1000,
          title:'创建运单失败'
        },
        {
          id:0,
          title:'配送到家'
        }
      ],
      order_steps:[],
      order_send_time:''
    };
  },
  mounted() {
    this.pubId = this.$route.query.id;
    this.initData();
    this.getStores();
  },
  filters: {
    goodsTypeFilter(val) {
      switch (val) {
        case 1:
          return "商超商品";
          break;
        case 2:
          return "生鲜商品";
          break;
      }
    },
    // 配送状态
    // '0：待调度\r\n\r\n20：已接单\r\n\r\n30：已取货\r\n\r\n50：已送达\r\n\r\n99：已取消',
    giveTypeFilter(val) {
      switch (val) {
        case 0:
          return "骑手接单中";
          break;
        case 20:
          return "骑手取货中";
          break;
        case 30:
          return "骑手配送中";
          break;
        case 50:
          return "骑手已送达";
          break;
        case 99:
          return "美团取消订单";
          break;
        default:
          break;
      }
    },
  },
  methods: {
    getTheStatusTitle(){
      for(let i=0;i<this.order_steps.length;i++){
        for(let j=0;j<this.delivery_status.length;j++ ){
          if(Number(this.order_steps[i].order_status) === this.delivery_status[j].id){
            this.order_steps[i].order_status=this.delivery_status[j].title
          }
        }
      }
      console.log()
    },
    getCustomerInfo(userId) {
      queryUserMember(userId).then((res) => {
        this.customerObj = res.data.body;
      });
    },
    initData() {
      orderOSM(this.pubId).then((res) => {
        this.clientInfo = res.data.body.lrUserBaseInfo;
        this.details = res.data.body;
        if(this.details.deliveryType==0 && this.details.estimatedDeliveryStartTime){//到家订单，才有预约时间//出现起始时间为空的情况，判空保护一下
          this.order_send_time = this.details.estimatedDeliveryStartTime.slice(0,16)+"-"+this.details.estimatedDeliveryEndTime.slice(11,16)
        }
        if(this.details.steps && this.details.steps.length > 0){
          this.order_steps=this.details.steps
        }
        this.getTheStatusTitle();
        this.goodsList = res.data.body.list;
        this.getCustomerInfo(res.data.body.createCustormer);
      });
    },
    getStoreString(e) {
      let temp = "";
      this.store_list.forEach((ele) => {
        if (ele.id == e) temp = ele.storeName;
      });
      return temp;
    },
    // 获取门店列表
    getStores() {
      listOfPage().then((res) => {
        this.store_list = res.data.body.list;
      });
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    // 立即呼叫骑手
    createDadaOrder() {
      let data = {
        id: this.pubId,
        callMode:1
      }
      createDadaOrder(data).then(res => {
        if(res.data.code === 200) {
          this.$message.success('呼叫骑手成功');
          this.initData();
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.wid-form {
  width: 300px;
}
.qrCode{
  width: 100px;
  height: 100px;
}
.order_step{
  margin: 12px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
      -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}
.order_step_item{
  width: 200px;
}
.icon{
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.iconfontSelf{
  font-size: 16px
};
</style>
