import request from '@/utils/request'

//获取百货订单列表
export function getOrderList (data) {
    return request({
        url: '/order-service/order/smallProgram',
        method: 'post',
        data
    })
}

//根据Id获取订单详情
export function getOrderDetail (id) {
    return request({
        url: "/order-service/order/smallProgram/" +id,
        method: 'post'
    })
}

//百货订单退款
export function orderRefund (data) {
    return request({
        url: "/order-service/order/refundOrder",
        method: 'post',
        data
    })
}

//订单退款接受
export function orderRefundAccept (data) {
    return request({
        url: "/order-service/orderOSM/regression",
        method: 'post',
        data
    })
}

//订单退拒绝
export function orderRefundRefuse (data) {
    return request({
        url: "/order-service/orderOSM/updateOrderData2",
        method: 'post',
        data
    })
}

//获取退款订单列表
export function getRefundOrderList (data) {
    return request({
        url: "/order-service/orderOSM/backstage",
        method: 'post',
        data
    })
}

//打印小票
export function printTicket (id) {
    return request({
        url: "/order-service/orderOSM/printingBill/" + id,
        method: 'get'
    })
}


//接口：运营人员审核退款订单
//地址：http://${BASE_URL}/order-service/order/refund/operation
export function refundOperation (data) {
    return request({
        url: "/order-service/order/refund/operation",
        method: 'post',
        data
    })
}

//接口：财务人员审核退款订单
//地址：http://${BASE_URL}/order-service/order/refund/finance
export function refundFinance (data) {
    return request({
        url: "/order-service/order/refund/finance",
        method: 'post',
        data
    })
}

//接口：运营人员确认退款结果
//地址：http://${BASE_URL}/order-service/order/refund/operationConfirm
export function refundOperationConfirm (data) {
    return request({
        url: "/order-service/order/refund/operationConfirm",
        method: 'post',
        data
    })
}

//接口：订单发货
//地址：http://${BASE_URL}/order-service/order/ship
export function confirmSendOut (data) {
    return request({
        url: "/order-service/order/ship",
        method: 'put',
        data
    })
}

//接口：生成退款定单（由运营人员发起）
//地址：http://${BASE_URL}/order-service/order/operation/refundOrder
export function refundOrder (data) {
    return request({
        url: "/order-service/order/operation/refundOrder",
        method: 'post',
        data
    })
}

//接口：修改物流单号
//地址：http://${BASE_URL}/order-service/order/updateShip
export function updateShip (data) {
    return request({
        url: "/order-service/order/updateShip",
        method: 'put',
        data
    })
}

//接口：打印百货订单小票（支持批量打印）
//地址：http://${BASE_URL}/order-service/order/printingBill
export function printSmallTicket (data) {
    return request({
        url: "/order-service/order/printingBill",
        method: 'post',
        data
    })
}


//接口：顺丰查询订单  //1:根据顺丰运单号查询
//地址：http://${BASE_URL}/customer-service/sfApi/querySfOrder?trackingType=#{trackingType}
export function getLogisticsInformation (data,type) {
    return request({
        url: `/customer-service/sfApi/querySfOrder?trackingType=${type}`,
        method: 'post',
        data
    })
}

export function updatePickingStatus(data) {
    return  request({
        url: '/order-service/orderOSM/pickingStatus',
        method: 'post',
        data
    })
}

export function createDadaOrder(data) {
    return request({
        url: '/small-inteface/dada/createOrder',
        method: 'post',
        data
    })
}

export function getDadaCancelRequestList(data) {
    return request({
        url: '/small-inteface/dada/getDadaCancelRequestList',
        method: 'post',
        data
    })
}

export function confirmMessage(data) {
    return request({
        url: '/small-inteface/dada/confirmMessage',
        method: 'post',
        data
    })
}
